import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { User } from 'src/app/core/models/user.model';
import { UpdateUser, GetUsers } from 'src/app/features/admin/users/users.state';
import { emailValidator } from 'src/app/shared/validators/email.validator';
import { passwordValidator } from 'src/app/shared/validators/password.validator';
import { Organization } from 'src/app/core/models/organization.model';
import { UpdateOrganization } from '../organization.state';
import { map, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'organization-manage-studies',
    templateUrl: './organization-manage-studies.component.html',
    styleUrls: ['./organization-manage-studies.component.scss'],
})
export class OrganizationManageStudiesComponent implements OnInit {
    public organizationId: string | null = null;
    public buttonColors = ButtonColors;
    public editingStudies = new Set<string>();
    public studiesFormGroup!: FormGroup;
    public studiesFormGroups: { [key: string]: FormGroup } = {};

    public selectOptions = [
        {
            label: 'En attente des données',
            value: 1,
        },
        {
            label: 'Traitement en cours',
            value: 2,
        },
        {
            label: 'Disponible',
            value: 3,
        },
    ];

    //TODO get real data
    public studies = [
        {
            id: 'ff243e04-67ad-427c-aa37-aff6e8b9d4a1',
            year: 2024,
            status: 1,
            description:
                'Description du contrôle de concession 2024 de l’organisation sélectionnée.',
        },
        {
            id: '537805ac-d569-4e52-bbef-d8477832e147',
            year: 2023,
            status: 2,
            description: 'Description ...',
        },
        {
            id: '0c54928a-6886-4c17-8c6d-72619e69c582',
            year: 2022,
            status: 3,
            description: '',
        },
    ];

    constructor(
        private readonly alertService: AlertService,
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly cdr: ChangeDetectorRef,
        private router: Router,
    ) {}

    ngOnInit() {
        this.route.paramMap.pipe(untilDestroyed(this)).subscribe((paramMap) => {
            this.organizationId = paramMap.get('id');
        });

        this.studies.forEach((study) => {
            this.studiesFormGroups[study.id] = this.formBuilder.group({
                status: [study.status, Validators.required],
                description: [study.description, Validators.required],
            });
        });
    }

    editStudy(id: string): void {
        this.editingStudies.add(id);
        this.cdr.detectChanges();
    }

    saveChanges(id: string): void {
        const updatedData = this.studiesFormGroups[id].value;
        console.log('Enregistrer', updatedData);

        const studyIndex = this.studies.findIndex((study) => study.id === id);
        if (studyIndex !== -1) {
            this.studies[studyIndex] = { ...this.studies[studyIndex], ...updatedData };
        }

        this.editingStudies.delete(id);
    }

    cancelEdit(id: string): void {
        this.editingStudies.delete(id);
    }

    getLabelStatut(idStatus: number): string {
        const option = this.selectOptions.find((option) => option.value === idStatus);
        return option ? option.label : '';
    }

    getBadgeClass(status: number): string {
        switch (status) {
            case 1:
                return 'pending';
            case 2:
                return 'inProgress';
            default:
                return 'available';
        }
    }

    openConcession(id: string) {
        this.router.navigate(['main']);
    }
}
