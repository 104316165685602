import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, notAuthGuard, adminAuthGuard, tokenGuard } from './core/guards/auth.guard';

import { AuthModule } from './features/auth/auth.module';
import { AdminModule } from './features/admin/admin.module';

import { ContactComponent } from './features/main/contact/contact.component';
import { HomeComponent } from './features/main/home/home.component';
import { StatsComponent } from './features/main/stats/stats.component';
import { FilesComponent } from './features/main/files/files.component';
import { MainModule } from './features/main/main.module';

import { MainComponent } from './features/main/main.component';

import { AuthComponent } from './features/auth/auth.component';
import { LoginComponent } from './features/auth/login/login.component';
import { ForgotPasswordComponent } from './features/auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './features/auth/change-password/change-password.component';
import { SignUpComponent } from './features/auth/signup/signup.component';

import { AdminComponent } from './features/admin/admin.component';
import { AccountComponent } from './features/main/settings/account/account.component';
import { OrganizationComponent } from './features/admin/organization/organization.component';
import { OrganizationDetailComponent } from './features/admin/organization/organization-detail/organization-detail.component';
import { UsersComponent } from './features/admin/users/users.component';
import { OrganizationManageStudiesComponent } from './features/admin/organization/organization-manage-studies/organization-manage-studies.component';
import { OrganizationGeneralComponent } from './features/admin/organization/organization-general/organization-general.component';
import { canDeactivateGuard } from './features/main/settings/account/account.guard';
import { GeneralComponent } from './features/main/general/general.component';
import { SourcepostsComponent } from './features/main/sourceposts/sourceposts.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        canActivate: [notAuthGuard],
        children: [
            {
                path: '',
                component: LoginComponent,
            },
        ],
    },
    {
        path: 'forgot-password',
        component: AuthComponent,
        children: [
            {
                path: '',
                component: ForgotPasswordComponent,
            },
        ],
    },
    {
        path: 'change-password',
        component: AuthComponent,
        canActivate: [tokenGuard],
        children: [
            {
                path: '',
                component: ChangePasswordComponent,
            },
        ],
    },
    {
        path: 'signup',
        component: AuthComponent,
        canActivate: [tokenGuard],
        children: [
            {
                path: '',
                component: SignUpComponent,
            },
        ],
    },
    {
        path: 'main',
        component: MainComponent,
        canActivate: [authGuard],
        children: [
            { path: '', component: HomeComponent },
            { path: 'general', component: GeneralComponent },
            { path: 'sourceposts', component: SourcepostsComponent },
            { path: 'bt-networks', component: StatsComponent },
            {
                path: 'hta-networks',
                component: StatsComponent,
                children: [
                    { path: 'burial-hta', component: StatsComponent },
                    { path: 'networks-age', component: StatsComponent },
                ],
            },
            { path: 'transformer-stations', component: StatsComponent },
            { path: 'quality', component: StatsComponent },
            { path: 'expenses', component: StatsComponent },
            { path: 'accounting', component: StatsComponent },
            { path: 'distribution_customers', component: StatsComponent },
            { path: 'supplier_customers', component: StatsComponent },
            { path: 'territory_sheet', component: StatsComponent },
            { path: 'export_report', component: StatsComponent },
            { path: 'account', component: AccountComponent, canDeactivate: [canDeactivateGuard] },
        ],
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [adminAuthGuard],
        children: [
            { path: 'organization', component: OrganizationComponent },
            {
                path: 'organization-detail/:id',
                component: OrganizationDetailComponent,
                children: [
                    { path: 'general', component: OrganizationGeneralComponent },
                    { path: 'users', component: UsersComponent },
                    { path: 'manageStudies', component: OrganizationManageStudiesComponent },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), AuthModule, MainModule, AdminModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
