<div class="page-content">
    <div class="page-header">
        <h1>Ouvrages</h1>
        <div class="page-description">Postes sources</div>
    </div>

    <div class="page-body">
        
        <div class="settings-menu">
            <div *ngFor="let item of menuItems$ | async"
                class="settings-item"
                [routerLink]="item.routerLink"
                routerLinkActive="selected"
                [routerLinkActiveOptions]="item.routerLinkActiveOptions">
                {{ item.display }}
            </div>
          </div>

        <div class="cards-container">
            <div class="row">
                @for (card of fourCards; track card) {
                    <div class="col-sm-6 col-lg-3 g-3">
                        <ed-card
                            [title]="card.title"
                            [body]="card.body"
                            [badge]="card.badge"
                            [badgeStyle]="card.badgeStyle"
                            [footer]="card.footer"
                            class="h-100"></ed-card>
                    </div>
                }
            </div>

            <div class="row">
                @for (card of threeCards; track card) {
                    <div class="col-sm-6 col-lg-4 g-3">
                        <ed-card
                            [title]="card.title"
                            [body]="card.body"
                            [badge]="card.badge"
                            [badgeStyle]="card.badgeStyle"
                            class="h-100"></ed-card>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
