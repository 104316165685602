import { Component } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, of } from 'rxjs';

interface SettingsMenuItem {
    display: string;
    routerLink: any[];
    routerLinkActiveOptions?: any;
}
@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss'],
})
export class StatsComponent {
    public menuItems$: Observable<SettingsMenuItem[]> | undefined;
    public fourCards = [
        {
            title: 'Date signature contrat de concession',
            body: '2021',
            badge: '',
            badgeStyle: '',
            footer: 'Hell yeah!',
        },
        {
            title: 'Durée du contrat de concession en vigueur',
            body: '4 ans',
            badge: '',
            badgeStyle: '',
        },
        {
            title: 'Nom du délégataire',
            body: 'Enedis',
            badge: '',
            badgeStyle: '',
        },
        {
            title: 'Exercice traité',
            body: '3e année',
            badge: '+',
            badgeStyle: '',
        },
    ];

    public threeCards = [
        {
            title: 'Taux d’enfouissement HTA',
            body: '62 %',
            badge: '+ 2%',
            badgeStyle: 'success',
        },
        {
            title: 'Critère B HIX',
            body: '12 min',
            badge: '+ 1 min',
            badgeStyle: 'warning',
        },
        {
            title: 'Investissements totaux',
            body: '32 M€',
            badge: '- 2M',
            badgeStyle: 'danger',
        },
    ];

    ngOnInit() {
        this.menuItems$ = of(null).pipe(
            map(() => {
                const items = [
                    {
                        display: 'Enfouissement HTA',
                        routerLink: ['burial-hta'],
                        routerLinkActiveOptions: { exact: false },
                    },
                    {
                        display: 'Age des réseaux  HTA et points de vigilances',
                        routerLink: ['networks-age'],
                        routerLinkActiveOptions: { exact: false },
                    },
                ];

                return items;
            }),
        );
    }
}
