<div class="page-content tab-studies">
  <div class="row">
  <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="paragraph">
            <div class="heading">
              <h1>CRAC</h1>
              <div class="subheading">Accéder à vos exercices</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="paragraph">
            <div class="heading">
              <h1>Contrôle de concession</h1>
              <div class="subheading">Accéder à vos exercices</div>
            </div>

            <div *ngFor="let study of studies;" class="exercise" [class.active]="editingStudies.has(study.id)">
              <ng-container *ngIf="!editingStudies.has(study.id); else editTemplate">
                <div class="header d-flex align-items-center">
                  <h2>{{ study.year }}</h2>
                  <div class="actions">
                    <ed-button [icon]="'icon-edit-02'" (click)="editStudy(study.id)">Modifier</ed-button>
                    <ed-button [colorSchema]="buttonColors.Secondary" (click)="openConcession(study.id)">Ouvrir</ed-button>
                  </div>
                </div>
                <div class="badge rounded-pill mt-2" [ngClass]="getBadgeClass(study.status)">
                  {{getLabelStatut(study.status)}}
                </div>
                <p class="description">{{ study.description }}</p>
              </ng-container>
              
              <ng-template #editTemplate>
                <div class="active">
                  <div class="exercise-header">
                    <h2>{{ study.year }}</h2>
                    <div class="actions">
                      <ed-button [icon]="'icon-save-01'" (click)="saveChanges(study.id)">Enregistrer</ed-button>
                      <ed-button [colorSchema]="buttonColors.Secondary" (click)="cancelEdit(study.id)">Annuler</ed-button>
                    </div>
                  </div>
                  <form [formGroup]="studiesFormGroups[study.id]">
                    <div class="mt-2">
                      <label for="status">Statut</label>
                      <ed-select
                        formControlName="status"
                        [disableRipple]="true"
                        [disableOptionCentering]="true"
                        selectId="study.status"
                        [options]="selectOptions">
                      </ed-select>
                    </div>
                    <div class="mt-2">
                      <label for="description">Description</label>
                      <ed-textarea 
                        innerId="sharedNotes"
                        [baseHeight]="180"
                        [isResizable]="true"
                        [maxTextSize]="10000"
                        formControlName="description"
                        [placeholder]="'Saisir une description'">
                      </ed-textarea>
                    </div>
                    <ed-button [icon]="'icon-download-cloud-02'">Téléverser le rapport PDF</ed-button>
                  </form>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

