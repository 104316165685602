<div class="page-content">
    <div class="page-header">
        <h1>Accueil</h1>
        <div class="page-description">Contrôle de concession</div>
    </div>

    <div class="page-body">
    
        
    </div>
</div>
